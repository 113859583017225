<template>
  <div class="content">
    <div class="top">
      <a-input allowClear v-model="params.name" class="w-200 mb-5" placeholder='标题搜索'></a-input>
      <a-select allowClear showSearch class="ml-5 w-200 mb-5" v-model="params.type" placeholder="应用场景">
        <a-select-option v-for="item in faqBigCategoryList" :key="item.value" :value="item.value">{{ item.name }}</a-select-option>
      </a-select>
      <a-button class="mb-5" type="primary" @click="search">搜索</a-button>
      <a-button class="mb-5" @click="handleAdd">添加</a-button>
    </div>
    <div class="table-content">
      <TableList :list="data" @success="getList"/>
    </div>
<!--  添加分类  -->
    <EditorFaqCategoryPopup ref="editorFaqCategoryPopupEl" @success="getList"/>
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/contentDataMange/faqAboutManage/faqCategoryManage/_components/TableList/index.vue"
import EditorFaqCategoryPopup
  from "@/views/cmsPage/contentDataMange/faqAboutManage/faqCategoryManage/_components/EditorFaqCategoryPopup/index.vue"
import {getFaqCategoryListDataApi} from "@/views/cmsPage/contentDataMange/faqAboutManage/faqCategoryManage/_apis"
import {faqBigCategoryList} from "@/views/cmsPage/contentDataMange/faqAboutManage/_data"

export default {
  mixins: [],
  components: {
    TableList, EditorFaqCategoryPopup,
  },
  data() {
    return {
      data: [],
      faqBigCategoryList, // faq大分类应用场景
      params: {
        type: undefined, // 应用场景
        name: undefined, // faq搜索分类标题
      },
    };
  },
  async created() {
    await this.getList()
  },
  methods: {
    /** 添加 */
    handleAdd() {
      this.$refs.editorFaqCategoryPopupEl.show()
    },
    /** 搜索 */
    search() {
      this.getList();
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getFaqCategoryListDataApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.data = res.data;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  button {
    margin-left: 10px;
  }
}
</style>
