var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("a-input", {
            staticClass: "w-200 mb-5",
            attrs: { allowClear: "", placeholder: "标题搜索" },
            model: {
              value: _vm.params.name,
              callback: function($$v) {
                _vm.$set(_vm.params, "name", $$v)
              },
              expression: "params.name"
            }
          }),
          _c(
            "a-select",
            {
              staticClass: "ml-5 w-200 mb-5",
              attrs: {
                allowClear: "",
                showSearch: "",
                placeholder: "应用场景"
              },
              model: {
                value: _vm.params.type,
                callback: function($$v) {
                  _vm.$set(_vm.params, "type", $$v)
                },
                expression: "params.type"
              }
            },
            _vm._l(_vm.faqBigCategoryList, function(item) {
              return _c(
                "a-select-option",
                { key: item.value, attrs: { value: item.value } },
                [_vm._v(_vm._s(item.name))]
              )
            }),
            1
          ),
          _c(
            "a-button",
            {
              staticClass: "mb-5",
              attrs: { type: "primary" },
              on: { click: _vm.search }
            },
            [_vm._v("搜索")]
          ),
          _c(
            "a-button",
            { staticClass: "mb-5", on: { click: _vm.handleAdd } },
            [_vm._v("添加")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("TableList", {
            attrs: { list: _vm.data },
            on: { success: _vm.getList }
          })
        ],
        1
      ),
      _c("EditorFaqCategoryPopup", {
        ref: "editorFaqCategoryPopupEl",
        on: { success: _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }