<template>
  <div>
    <a-table
        :columns="tableList"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--  应用场景    -->
      <div slot="itemBigTypeSlot" slot-scope="row">
        <div :class="faqBigCategoryClassType[row.type]">{{ computedFaqBigCategoryListMapText(row.type) }}</div>
      </div>
<!--  标题    -->
      <div slot="itemFaqTitleNameSlot" slot-scope="row">
        <div class="font-weight-bold">{{ row.name }}</div>
      </div>
<!--  key    -->
      <div slot="itemFaqTitleKeySlot" slot-scope="row">
        <div class="font-weight-bold">{{ row.subType }}</div>
      </div>
<!--  操作    -->
      <div slot="itemActionSlot" slot-scope="row">
        <a-button type="primary" @click="handleEditor(row)">编辑</a-button>
        <div class="mt-5">
          <a-popconfirm title="确认删除当前分类？" @confirm="handleDelete(row)">
            <a-button type="danger">删除</a-button>
          </a-popconfirm>
        </div>
      </div>
    </a-table>
<!--  编辑弹窗  -->
    <EditorFaqCategoryPopup ref="editorFaqCategoryPopupEl" @success="handleSuccess"/>
  </div>
</template>
<script>
import {faqCategoryTableColumns} from "@/views/cmsPage/contentDataMange/faqAboutManage/faqCategoryManage/_data"
import EditorFaqCategoryPopup
  from "@/views/cmsPage/contentDataMange/faqAboutManage/faqCategoryManage/_components/EditorFaqCategoryPopup/index.vue"
import {faqBigCategoryClassType, faqBigCategoryListMapText} from "@/views/cmsPage/contentDataMange/faqAboutManage/_data"
import {postDelFaqCategoryItemApi} from "@/views/cmsPage/contentDataMange/faqAboutManage/faqCategoryManage/_apis"

export default {
  components: {EditorFaqCategoryPopup},
  props: ['pagination', 'list'],
  data() {
    return {
      faqBigCategoryClassType, // 应用场景对应样式类
      tableList: faqCategoryTableColumns,
    }
  },
  computed: {
    computedFaqBigCategoryListMapText() {
      return (value) => faqBigCategoryListMapText(value)
    }
  },
  methods: {
    /** 确定删除 */
    async handleDelete(row) {
      this.$loading.show()
      const res = await postDelFaqCategoryItemApi({id: row.id})
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('删除成功')
      this.handleSuccess()
    },
    /** 编辑 */
    handleEditor(row) {
      this.$refs.editorFaqCategoryPopupEl.show(row)
    },
    /** 成功 */
    handleSuccess() {
      this.$emit('success')
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
  }
}
</script>
<style lang="scss" scoped>
</style>