<template>
  <a-modal v-model="showPopup" :width='500' :title="titleText" @cancel="handleCancel" @ok="handleConfirm">
    <a-form-model v-bind="layout">
      <a-form-item label="应用场景">
        <a-select allowClear showSearch class="w-200" v-model="params.type" placeholder="场景类型">
          <a-select-option v-for="item in faqBigCategoryList" :key="item.value" :value="item.value">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="faq分类标题">
        <a-input
            allowClear
            v-model="params.name"
            class="w-200"
            placeholder='faq分类标题'
        ></a-input>
      </a-form-item>
      <a-form-item label="faq分类Key">
        <a-input
            allowClear
            v-model="params.subType"
            :disabled="isEditStatus"
            autoSize
            class="w-100-w"
            placeholder='必须为英文，不能重复'
        ></a-input>
        <div class="mt-5 color-red">必须为英文，不能与其他任意一个分类的Key重复！！！</div>
      </a-form-item>
    </a-form-model>
  </a-modal>
</template>
<script>

import {faqBigCategoryList} from "@/views/cmsPage/contentDataMange/faqAboutManage/_data"
import {isEnglishByStr, updateBaseObjectWithComplex} from "@/untils/otherEvent"
import {
  postAddFaqCategoryItemApi,
  postEditFaqCategoryItemApi
} from "@/views/cmsPage/contentDataMange/faqAboutManage/faqCategoryManage/_apis"

export default {
  emits: ['success'],
  data() {
    return {
      showPopup: false,
      titleText: '添加faq分类',
      faqBigCategoryList,
      layout: {
        labelCol: {span: 5}, //标签占5份  共24份
        wrapperCol: {span: 19},
      },
      params: {
        id: undefined,
        type: undefined,
        subType: undefined,
        name: undefined,
      },
      isEditStatus: false,
    }
  },
  methods: {
    show(data) {
      if (data) {
        this.isEditStatus = true
        this.titleText = '编辑faq分类'
        this.params = updateBaseObjectWithComplex(this.params, data)
      } else {
        this.isEditStatus = false
      }
      this.showPopup = true
    },
    /** 确定修改 */
    async handleConfirm() {
      if (!this.params.type) return this.$message.warning('应用场景不能为空')
      if (!this.params.name) return this.$message.warning('分类标题不能为空')
      if (!this.params.subType || !isEnglishByStr(this.params.subType)) return this.$message.warning('分类Key必须为纯英文，不可重复，不可为空')
      this.$loading.show()
      let res
      if (this.isEditStatus) {
        res = await postEditFaqCategoryItemApi(this.params)
      } else {
        res = await postAddFaqCategoryItemApi(this.params)
      }
      this.$loading.hide()
      if (res.status !== '200') return;
      this.$message.info(`${this.isEditStatus ? '编辑成功' : '添加成功'}`)
      this.$emit('success')
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        id: undefined,
        type: undefined,
        subType: undefined,
        name: undefined,
      }
      this.showPopup = false
    }
  }
}
</script>