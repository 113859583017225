var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 500, title: _vm.titleText },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b({}, "a-form-model", _vm.layout, false),
        [
          _c(
            "a-form-item",
            { attrs: { label: "应用场景" } },
            [
              _c(
                "a-select",
                {
                  staticClass: "w-200",
                  attrs: {
                    allowClear: "",
                    showSearch: "",
                    placeholder: "场景类型"
                  },
                  model: {
                    value: _vm.params.type,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "type", $$v)
                    },
                    expression: "params.type"
                  }
                },
                _vm._l(_vm.faqBigCategoryList, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.value, attrs: { value: item.value } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "faq分类标题" } },
            [
              _c("a-input", {
                staticClass: "w-200",
                attrs: { allowClear: "", placeholder: "faq分类标题" },
                model: {
                  value: _vm.params.name,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "name", $$v)
                  },
                  expression: "params.name"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "faq分类Key" } },
            [
              _c("a-input", {
                staticClass: "w-100-w",
                attrs: {
                  allowClear: "",
                  disabled: _vm.isEditStatus,
                  autoSize: "",
                  placeholder: "必须为英文，不能重复"
                },
                model: {
                  value: _vm.params.subType,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "subType", $$v)
                  },
                  expression: "params.subType"
                }
              }),
              _c("div", { staticClass: "mt-5 color-red" }, [
                _vm._v("必须为英文，不能与其他任意一个分类的Key重复！！！")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }