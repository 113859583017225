import instance from "@/axios"

/** 获取faq列表数据API */
export const getFaqCategoryListDataApi = async (params) => {
    return instance({
        url: '/dq_admin/faqQuestionCategory/list',
        method: 'GET',
        params: params
    })
}

/** 确定添加-faq分类 */
export const postAddFaqCategoryItemApi = async (params) => {
    return instance({
        url: '/dq_admin/faqQuestionCategory/add',
        method: 'post',
        data: params
    })
}
/** 确定编辑-faq分类 */
export const postEditFaqCategoryItemApi = async (params) => {
    return instance({
        url: '/dq_admin/faqQuestionCategory/edit',
        method: 'post',
        data: params
    })
}
/** 确定删除-faq分类 */
export const postDelFaqCategoryItemApi = async (params) => {
    return instance({
        url: '/dq_admin/faqQuestionCategory/delById',
        method: 'get',
        params: params
    })
}