var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemBigTypeSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { class: _vm.faqBigCategoryClassType[row.type] }, [
                  _vm._v(
                    _vm._s(_vm.computedFaqBigCategoryListMapText(row.type))
                  )
                ])
              ])
            }
          },
          {
            key: "itemFaqTitleNameSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(row.name))
                ])
              ])
            }
          },
          {
            key: "itemFaqTitleKeySlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(row.subType))
                ])
              ])
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleEditor(row)
                        }
                      }
                    },
                    [_vm._v("编辑")]
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-5" },
                    [
                      _c(
                        "a-popconfirm",
                        {
                          attrs: { title: "确认删除当前分类？" },
                          on: {
                            confirm: function($event) {
                              return _vm.handleDelete(row)
                            }
                          }
                        },
                        [
                          _c("a-button", { attrs: { type: "danger" } }, [
                            _vm._v("删除")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c("EditorFaqCategoryPopup", {
        ref: "editorFaqCategoryPopupEl",
        on: { success: _vm.handleSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }